var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"编辑","visible":_vm.visible,"footer":null,"width":"800px"},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"总承包(EPC)项目名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['projectName', 
              { 
                            initialValue: _vm.detail.projectName,
                rules: [{ required: true, message: '请输入' }] 
                },]),expression:"['projectName', \n              { \n                            initialValue: detail.projectName,\n                rules: [{ required: true, message: '请输入' }] \n                },]"}],attrs:{"disabled":true}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"联合体牵头方"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['leaderDeptName', 
              { 
                            initialValue: _vm.detail.leaderDeptName,
                rules: [{ required: true, message: '请输入' }] },]),expression:"['leaderDeptName', \n              { \n                            initialValue: detail.leaderDeptName,\n                rules: [{ required: true, message: '请输入' }] },]"}],attrs:{"disabled":true}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"联合体成员方"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['memberDeptName', 
              { 
                            initialValue: _vm.detail.memberDeptName,
                
                 },]),expression:"['memberDeptName', \n              { \n                            initialValue: detail.memberDeptName,\n                \n                 },]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"合约项目经理"}},[_c('EmployeeSelector',{attrs:{"value":_vm.contractProjectManager.name ? [_vm.contractProjectManager] : []},on:{"change":(arr) => {
                  if (arr.length > 0) {
                    _vm.contractProjectManager = arr[0];
                  } else {
                    _vm.contractProjectManager = {};
                  }
                }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.contractProjectManager.name)?_c('div',[_vm._v(" "+_vm._s(_vm.contractProjectManager.name)+" ")]):_c('div',[_vm._v(" ")])])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"现场经理"}},[_c('EmployeeSelector',{attrs:{"value":_vm.siteManager.name ? [_vm.siteManager] : []},on:{"change":(arr) => {
                  if (arr.length > 0) {
                    _vm.siteManager = arr[0];
                  } else {
                    _vm.siteManager = {};
                  }
                }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.siteManager.name)?_c('div',[_vm._v(" "+_vm._s(_vm.siteManager.name)+" ")]):_c('div',[_vm._v(" ")])])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"安全管理人员"}},[_c('EmployeeSelector',{attrs:{"value":_vm.safetyManager.name ? [_vm.safetyManager] : []},on:{"change":(arr) => {
                  if (arr.length > 0) {
                    _vm.safetyManager = arr[0];
                  } else {
                    _vm.safetyManager = {};
                  }
                }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.safetyManager.name)?_c('div',[_vm._v(" "+_vm._s(_vm.safetyManager.name)+" ")]):_c('div',[_vm._v(" ")])])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"施工现场开工时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['startTime', 
              { 
                            initialValue: _vm.detail.startTime ? _vm.moment(_vm.detail.startTime) : undefined,
                },]),expression:"['startTime', \n              { \n                            initialValue: detail.startTime ? moment(detail.startTime) : undefined,\n                },]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"施工现场竣工时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['endTime', 
              { 
                            initialValue: _vm.detail.endTime ? _vm.moment(_vm.detail.endTime) : undefined,
                },]),expression:"['endTime', \n              { \n                            initialValue: detail.endTime ? moment(detail.endTime) : undefined,\n                },]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1)],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.saveLoading}},[_vm._v("确认修改")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }