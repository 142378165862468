<template>

    <a-modal title="编辑" :visible="visible" :footer="null" @cancel="cancel" width="800px">

    <a-form
      :colon="false"
      @submit="handleSubmit"
      :form="form"
    >
      <a-row :gutter="8">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="总承包(EPC)项目名称">
            <a-input
            :disabled='true'
              v-decorator="['projectName', 
                  { 
                                initialValue: detail.projectName,
                    rules: [{ required: true, message: '请输入' }] 
                    },]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="联合体牵头方">
            <a-input
              :disabled='true'
              v-decorator="['leaderDeptName', 
                  { 
                                initialValue: detail.leaderDeptName,
                    rules: [{ required: true, message: '请输入' }] },]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="联合体成员方">
                       <a-input
              v-decorator="['memberDeptName', 
                  { 
                                initialValue: detail.memberDeptName,
                    
                     },]"
            />
          </a-form-item>
        </a-col>

                <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="合约项目经理">
                       <EmployeeSelector
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        contractProjectManager = arr[0];
                      } else {
                        contractProjectManager = {};
                      }
                    }
                  "
                  :value="contractProjectManager.name ? [contractProjectManager] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="contractProjectManager.name">
                      {{ contractProjectManager.name }}
                    </div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
          </a-form-item>
        </a-col>


                <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="现场经理">
                    <EmployeeSelector
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        siteManager = arr[0];
                      } else {
                        siteManager = {};
                      }
                    }
                  "
                  :value="siteManager.name ? [siteManager] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="siteManager.name">
                      {{ siteManager.name }}
                    </div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
          </a-form-item>
        </a-col>


                <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="安全管理人员">
                                <EmployeeSelector
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        safetyManager = arr[0];
                      } else {
                        safetyManager = {};
                      }
                    }
                  "
                  :value="safetyManager.name ? [safetyManager] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="safetyManager.name">
                      {{ safetyManager.name }}
                    </div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
          </a-form-item>
        </a-col>


                <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="施工现场开工时间">
                    <a-date-picker
          style="width: 100%"
          placeholder=""
                    v-decorator="['startTime', 
                  { 
                                initialValue: detail.startTime ? moment(detail.startTime) : undefined,
                    },]"
        />
          </a-form-item>
        </a-col>
                        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="施工现场竣工时间">
                                <a-date-picker
          style="width: 100%"
          placeholder=""
                    v-decorator="['endTime', 
                  { 
                                initialValue: detail.endTime ? moment(detail.endTime) : undefined,
                    },]"
        />
          </a-form-item>
        </a-col>
 
      </a-row>

      <div class="right">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button htmlType="submit" type="primary" :loading="saveLoading">确认修改</a-button>
        </a-space>
      </div>
    </a-form>

    </a-modal>

</template>

<script>
import EmployeeSelector from "@/components/employee-selector";
import request from "@/api/request";
import moment from 'moment'

function modify(data) {
  return request({
    url: "/office-service/quality/inspect/project/modify",
    method: "post",
    data
  });
}

export default {

  components: {
    EmployeeSelector
  },

    props: {
    visible: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },


  data() {
    return {
      form: this.$form.createForm(this),

      contractProjectManager: {},
      siteManager: {},
      safetyManager: {},

      saveLoading: false
    };
  },

  watch: {
    visible() {
      if (this.visible) {

      this.contractProjectManager = {
        userId: this.detail.contractProjectManagerId,
        name: this.detail.contractProjectManagerName,
      };
      this. siteManager = {
        userId: this.detail.siteManagerId,
        name: this.detail.siteManagerName,
      };

      this.safetyManager = {
        userId: this.detail.safetyManagerId,
        name: this.detail.safetyManagerName
      };

      }
    }
  },

  methods: {
    moment,

    cancel() {
      this.form.resetFields();
            this.contractProjectManager = {};
            this.siteManager = {};
            this.safetyManager = {};
      this.$emit('cancel')
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log("values", values);
        if (!err) {
          this.save(values);
        }
      });
    },

    save(values) {
      this.saveLoading = true;

      modify({
        ...this.detail,
        ...values,
        startTime: values.startTime ? values.startTime.format("YYYY-MM-DD") : undefined,
        endTime: values.endTime ? values.endTime.format("YYYY-MM-DD") : undefined,

    contractProjectManagerId: this.contractProjectManager.userId,
    contractProjectManagerName: this.contractProjectManager.name,

    siteManagerId: this.siteManager.userId,
    siteManagerName: this.siteManager.name,

        safetyManagerId: this.safetyManager.userId,
    safetyManagerName: this.safetyManager.name,

      })
        .then(() => {
          this.cancel();
          this.$emit('refresh')
        })
        .finally(() => {
          this.saveLoading = false;
        });
    }
  }
};
</script>