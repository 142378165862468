<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="24">
          <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.projectName" placeholder="项目名称" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
      >
        <a-table-column title="总承包(EPC)项目名称" data-index="projectName" />

        <a-table-column title="联合体牵头方" data-index="leaderDeptName" align="center" />
        <a-table-column title="联合体成员方" data-index="memberDeptName" align="center" />
        <a-table-column title="合约项目经理" data-index="contractProjectManagerName" align="center" />
        <a-table-column title="现场经理" data-index="siteManagerName" align="center" />
        <a-table-column title="安全管理人员" data-index="safetyManagerName" align="center" />
        <a-table-column title="施工现场开工时间" data-index="startTime" align="center" />
        <a-table-column title="施工现场竣工时间" data-index="endTime" align="center" />

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a
                v-if="$getPermission($route.path + '/edit')"
                href="#"
                @click.prevent="edit(text)"
              >编辑</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

    <Edit :visible="visible" :detail="detail" @cancel="visible = false" @refresh='getList' />
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";

import Edit from "./edit.vue";

function fetchList(data) {
  return request({
    url: "/office-service/quality/inspect/project/listPage",
    method: "post",
    data
  });
}


export default {
  mixins: [watermark],

  components: {
    Edit,
  },

  data() {
    return {
      form: {},

      loading: false,
      list: [],
      total: 0,
      current: 1,
      pageSize: 10,

      visible: false,
      detail: {}
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.current = 1;
      this.form = {};
      this.getList();
    },

    edit(text) {
      this.detail = text;
      this.visible = true;
    }

  }
};
</script>
